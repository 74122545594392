import { Spin } from 'antd';
import Header from 'components/Header';
import Toolbar from 'components/Toolbar';
import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthWrapper from './../AuthWrapper/index';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useQuery } from 'react-query';
import { calculateAccess, getProfile, getResources } from 'api/authentication';
import { getListTeam } from 'api/player';
import { isMobile } from 'react-device-detect';

import styles from './styles.module.scss';
import Footer from 'components/Footer';
import { TwitterType, WebRouter } from 'common';
import { history } from 'App';
import classNames from 'classnames';
import { getCurrentFantasyYear } from 'helper';

const Login = lazy(() => import('pages/Login'));
const Signup = lazy(() => import('pages/Signup'));
const TopPage = lazy(() => import('pages/TopPage'));
const ChangePassword = lazy(() => import('pages/ChangePassword'));
const Term = lazy(() => import('pages/Term'));
const Inquiry = lazy(() => import('pages/Inquiry'));
const Verify = lazy(() => import('pages/Verify'));
const DetailPlayer = lazy(() => import('pages/DetailPlayer'));
const DetailTeam = lazy(() => import('pages/DetailTeam'));
const BoxScores = lazy(() => import('pages/BoxScores'));
const BoxScoresDFS = lazy(() => import('pages/BoxScoresDFS'));
const PastContest = lazy(() => import('pages/PastContest'));
const Maintain = lazy(() => import('pages/Maintain'));
const PlayerRankingsSeason = lazy(() => import('pages/PlayerRankingsSeason'));
const PlayerRankings = lazy(() => import('pages/PlayerRankings'));
const DailySummaryDFS = lazy(() => import('pages/DailySummaryDFS'));
const DailySummary = lazy(() => import('pages/DailySummary'));
const DepthCharts = lazy(() => import('pages/DepthCharts'));

const MaintainOffSeason = lazy(() => import('pages/Maintain/MaintainOffSeason'));

export default function RootWrapper() {
  const token = Cookies.get('token') || '';
  const isAuthenticated = Boolean(token);
  const twitterType = Cookies.get('twitterType');
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const oauth_token = query.get('oauth_token');
  const oauth_verifier = query.get('oauth_verifier');
  const location = useLocation();
  // if (location.pathname === '/home') {
  //   history.push('/');
  // }

  useQuery('profile', getProfile, {
    enabled: isAuthenticated,
  });

  // useQuery('list-teams', () => getListTeam(), {
  //   enabled: isAuthenticated,
  //   onSuccess: (data) => {
  //     console.log(data);
  //   },
  // });

  useQuery('resources', () => getResources(), {
    onSuccess({ data }) {
      localStorage.setItem('fantasyYear', getCurrentFantasyYear(data.seasons) + '');
    },
  });

  useEffect(() => {
    if (oauth_token && oauth_verifier) {
      if (twitterType === TwitterType.SIGNUP) navigate(`/sign-up${search}`);
      else {
        navigate(`/login${search}`);
      }
    }
  }, [search]);

  useEffect(() => {
    const generateRoute = (pathname: string) => {
      const patternPlayer = /^(\/detail-player\/[0-9]+)$/;
      const patternTeam = /^(\/detail-team\/[0-9]+)$/;
      if (patternTeam.test(pathname)) {
        return 'detail-player';
      }
      if (patternPlayer.test(pathname)) {
        return 'detail-team';
      }
      if (pathname === '/') {
        return 'home';
      }
      return pathname.slice(1);
    };
    const calculate = async () => {
      await calculateAccess({ route: generateRoute(location.pathname) });
    };
    calculate();
  }, [location.pathname]);

  return (
    <div className={classNames(styles.root_container, { [styles.mobile]: isMobile })}>
      <Header />
      <div className="content">
        <Toolbar />
        <div className={classNames('main-content', { maintenance: location.pathname === '/maintenance' })}>
          <Suspense
            fallback={
              <div className="spin">
                <Spin />
              </div>
            }
          >
            <Routes>
              <Route path={WebRouter.DAILY_SUMMARY} element={<DailySummaryDFS />} />
              <Route path={WebRouter.BOX_SCORE} element={<BoxScoresDFS />} />
              <Route path={WebRouter.PLAYER_RANKING} element={<PlayerRankings />} />
              <Route path={WebRouter.PAST_CONTEST} element={<PastContest />} />
              <Route path={WebRouter.DEPTH_CHART} element={<DepthCharts />} />
              <Route path={WebRouter.DAILY_SUMMARY_SS} element={<DailySummary />} />
              <Route path={WebRouter.BOX_SCORE_SS} element={<BoxScores />} />
              <Route path={WebRouter.PLAYER_RANKING_SS} element={<PlayerRankingsSeason />} />
              <Route path="/login" element={<Login />} />
              <Route path="/sign-up" element={<Signup />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/term" element={<Term />} />
              <Route path="/inquiry" element={<Inquiry />} />
              <Route path="/verify" element={<Verify />} />
              <Route path="/" element={<TopPage />} />
              <Route path="/maintain" element={<Maintain />} />
              <Route path="/maintenance" element={<MaintainOffSeason />} />

              <Route path="/detail-player/:id" element={<DetailPlayer />} />
              <Route path="/detail-team/:teamId" element={<DetailTeam />} />
              <Route path="/*" element={<AuthWrapper />} />
            </Routes>
          </Suspense>
        </div>
      </div>
      <Footer />
    </div>
  );
}
