import { notification } from 'antd';

interface IProps {
  type: 'success' | 'error';
  message: string | any;
  description?: string;
  onClose?: Function | any;
}

const CustomNotification = ({ type, message, description = '', onClose = () => {} }: IProps) => {
  notification.destroy();
  return notification[type]({
    message,
    description,
    onClose,
    getContainer: () => document.body,
  });
};

export default CustomNotification;
