const configs = {
  APP_ENV: process.env.REACT_APP_ENV,
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
  TWITTER_ID: process.env.REACT_APP_TWITTER_ID || '',
  TWITTER_SECRET_ID: process.env.REACT_APP_TWITTER_SECRET_ID || '',
  GMO_SHOP_ID: process.env.REACT_APP_GMO_SHOP_ID,
  TRACKING_ID: process.env.REACT_APP_TRACKING_ID || '',
  IS_SHOW_DOWNLOAD_CSV: process.env.REACT_APP_IS_SHOW_DOWNLOAD_CSV === "true"
};

export default configs;
