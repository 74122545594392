import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'services/store';
import App from './App';
import { ConfigProvider } from 'antd';
// import 'antd/dist/antd.variable.min.css';
import jaJP from 'antd/lib/locale/ja_JP';
import 'moment/locale/ja';

ReactDOM.render(
  <Provider>
    <React.StrictMode>
      <ConfigProvider
        locale={jaJP}
        // getPopupContainer={(trigger) => {
        //   if (trigger?.className?.includes('ant-select-selector') || trigger?.className?.includes('ant-picker'))
        //     return trigger?.parentNode;
        //   return document?.body as any;
        // }}
      >
        <App />
      </ConfigProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
