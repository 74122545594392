import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { MemberType, WebRouter } from 'common';
import { useQueryClient } from 'react-query';
import { text } from 'helper/text';
// import configs from 'config';

const TeamRankingsSeason = lazy(() => import('pages/TeamRankingsSeason'));
const H2HDaily = lazy(() => import('pages/H2HDailySeason'));
// const BoxScores = lazy(() => import('pages/BoxScores'));

export default function PrivateWrapper() {
  const queryClient = useQueryClient();
  const profile: any = queryClient.getQueryData('profile');
  const isPrivateAcc = profile?.data?.memberType === MemberType.IS_PRIVATE;

  if (isPrivateAcc) return <div className="mt-30">{text.privatePage}</div>;
  return (
    <Routes>
      {/* <Route path="/box-scored-ss" element={<BoxScores />} /> */}
      <Route path={WebRouter.TEAM_RANKING_SS} element={<TeamRankingsSeason />} />
      <Route path={WebRouter.H2H_DAILY} element={<H2HDaily />} />
    </Routes>
  );
}
