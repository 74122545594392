import { DISTRICT, AVG_PERIOD, Period, MemberType, NationalityType } from 'common';
import CustomNotification from 'components/CustomNotification';
import moment from 'moment';
import { text } from './text';

const offset = moment().utcOffset();
export const LIST_POSITIONS = ['PG', 'SG', 'SF', 'PF', 'C'];

export const handleErrorMessage = (error: any) => {
  CustomNotification({
    type: 'error',
    message: error?.response?.data?.errorMessage || 'Some thing went wrong',
  });
};

export const getQueryString = (search: string) => {
  return Object.fromEntries(
    search
      .replace('?', '')
      .split('&')
      .map((param) => param.split('='))
  );
};

export const VALIDATE_EMAIL =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const VALIDATE_CARD_NUMBER = /^[0-9]{16,16}$/;
export const VALIDATE_CVCCODE = /^[0-9]{3,3}$/;
export const VALIDATE_PASSWORD = /^[A-Za-z0-9]{8,20}$/;

const WEEK_DAY: any = {
  SUNDAY: {
    id: 0,
    name: '日',
  },
  MONDAY: {
    id: 1,
    name: '月',
  },
  TUESDAY: {
    id: 2,
    name: '火',
  },
  WEDNESDAY: {
    id: 3,
    name: '水',
  },
  THUSDAY: {
    id: 4,
    name: '木',
  },
  FRIDAY: {
    id: 5,
    name: '金',
  },
  SATURDAY: {
    id: 6,
    name: '土',
  },
};

export const convertDate = (date: string, format = 'YYYY/MM/DD', showTime = false) => {
  if (!date) return null;
  const dateLocal = moment(date, 'YYYY-MM-DD HH:mm:ss ZZ');
  const dayKey = Object.keys(WEEK_DAY).find((key: string | any) => WEEK_DAY[key].id === dateLocal.weekday()) || '';

  const day = dateLocal.format(format) + '\n' + `(${WEEK_DAY[dayKey].name}) `;

  return (
    <span className={dayKey === 'SATURDAY' ? 'color-blue2' : dayKey === 'SUNDAY' ? 'color-red2' : ''}>
      {day + (showTime ? dateLocal.format('HH:mm') : '')}
    </span>
  );
};

export const formatDecimalInt = (num: any) => {
  if (!!num) {
    const numFormatPercent = (num * 100).toFixed(1);
    return `${numFormatPercent}%`;
  }
  return `0.0%`;
};

export const formatDecimal = (num: any) => {
  if (!!num) {
    return `${Number(num).toFixed(1)}%`;
  }
  return `0.0%`;
};

export const csvUtils = {
  createSeparateRow(length: number) {
    return Array(length).fill('');
  },
  createHeaderTable(title: string, length: number) {
    return [title, ...Array(length - 1).fill('')];
  },
};

export const percent = (value: number | any) => {
  return !isNaN(value) ? Number(value).toFixed(1) + '%' : '';
};

export const price = (value: number) => (!Number.isNaN(Number(value)) ? Number(value).toFixed(1) + '万円' : '');

export const statsFormat = (value: number) => (typeof value === 'number' ? Number(value).toFixed(1) : '');

export const intFormat = (value: number) => (typeof value === 'number' ? Number(value).toFixed(0) : value);

export const FPPerPrice = (fp: number, price: number) =>
  (Boolean(fp) || fp === 0) && Boolean(price) ? Number(fp / price).toFixed(1) + '万円' : null;

export const sortByFPPrice = (aFp: number, aPrice: number, bFp: number, bPrice: number) => {
  const formatFPPerPrice = (fp: number, price: number) =>
    (Boolean(fp) || fp === 0) && Boolean(price) ? Number(fp / price) : 0;
  const valA = formatFPPerPrice(aFp, aPrice);
  const valB = formatFPPerPrice(bFp, bPrice);
  return valA - valB;
};

export const position = (position: object | any) => {
  return LIST_POSITIONS?.filter((item) => Boolean(position?.['pos' + item]))?.join('/');
};

export const sumOfArray = (
  arr: number[],
  index: string,
  stat:
    | 'value'
    | 'G'
    | 'GS'
    | 'MPG'
    | 'FGM'
    | 'FGA'
    | 'FTM'
    | 'FTA'
    | 'TRIFGM'
    | 'TRIFGA'
    | 'OR'
    | 'DR'
    | 'TR'
    | 'AST'
    | 'STL'
    | 'BLK'
    | 'TO'
    | 'TD'
    | 'DD'
    | 'F'
    | 'PTS'
    | 'ATO'
    | 'FGRate'
    | 'FTRate'
    | 'TRIFGRate',
  type: number
) => {
  let noDecimal: any = [];
  let oneDecimal: any = [];
  let twoDecimal: any = [];
  let percentFormat: any = [];

  if (AVG_PERIOD.includes(type)) {
    noDecimal = ['G', 'GS'];
    oneDecimal = [
      'value',
      'MPG',
      'FGM',
      'FGA',
      'FTM',
      'FTA',
      'TRIFGM',
      'TRIFGA',
      'OR',
      'DR',
      'TR',
      'AST',
      'STL',
      'BLK',
      'TO',
      'TD',
      'DD',
      'F',
    ];
    twoDecimal = ['ATO'];
    percentFormat = ['FGRate', 'FTRate', 'TRIFGRate'];
    if (
      [Period.AVG_GUESS_THIS_SS, Period.AVG_LAST_1_WEEK, Period.AVG_LAST_2_WEEK, Period.AVG_LAST_1_MONTH].includes(type)
    ) {
      noDecimal.push('PTS');
    } else {
      oneDecimal.push('PTS');
    }
  } else {
    noDecimal = [
      'G',
      'GS',
      'FGM',
      'FGA',
      'FTM',
      'FTA',
      'TRIFGM',
      'TRIFGA',
      'OR',
      'DR',
      'TR',
      'AST',
      'STL',
      'BLK',
      'TO',
      'TD',
      'DD',
      'F',
      'PTS',
    ];
    oneDecimal = ['value', 'MPG'];
    twoDecimal = ['ATO'];
    percentFormat = ['FGRate', 'FTRate', 'TRIFGRate'];
  }
  if (noDecimal.includes(stat)) {
    return (
      arr?.reduce((partial_sum, a: any) => partial_sum + Number.parseFloat(a[index]?.toFixed(0)), 0) || 0
    ).toFixed(0);
  }
  if (oneDecimal.includes(stat)) {
    return (
      arr?.reduce((partial_sum, a: any) => partial_sum + Number.parseFloat(a[index]?.toFixed(1)), 0) || 0
    ).toFixed(1);
  }
  if (twoDecimal.includes(stat)) {
    return (
      arr?.reduce((partial_sum, a: any) => partial_sum + Number.parseFloat(a[index]?.toFixed(2)), 0) || 0
    ).toFixed(2);
  }
  if (percentFormat.includes(stat)) {
    const sum = (
      arr?.reduce((partial_sum, a: any) => partial_sum + Number.parseFloat(a[index]?.toFixed(1)), 0) || 0
    ).toFixed(1);
    return `${Number.parseFloat(sum).toFixed(1)}%`;
  }
  return '0';
};

export const sumOfArrayNormal = (arr: any[], key: string) =>
  arr.reduce((partialSum, a) => partialSum + Number.parseFloat(a[key] || 0), 0);

export const avgOfArray = (arr: number[], index: string, decimal?: number) => {
  let sum = 0;
  if (decimal) {
    sum = arr?.reduce((partial_sum, a: any) => partial_sum + Number.parseFloat(a[index].toFixed(decimal)), 0);
  } else {
    sum = arr?.reduce((partial_sum, a: any) => partial_sum + a[index], 0);
  }
  return sum / arr.length || 0;
};

export const avgOfArrayHasChecked = (arr: number[], index: string) => {
  const sum = arr?.filter((item: any) => item.isChecked).reduce((partial_sum, a: any) => partial_sum + a[index], 0);
  return sum / arr?.filter((item: any) => item.isChecked).length || 0;
};

export const sortByPosition = (a: object | any, b: object | any, sortOrder?: 'ascend' | 'descend', posKey = 'pos') => {
  // PG<SG<SF<PF<C
  const list: any = {
    PG: 1,
    SG: 2,
    SF: 3,
    PF: 4,
    C: 5,
  };
  let valA = 0;
  let valB = 0;
  let numA = 1;
  let numB = 1;
  Object.keys(list)?.forEach((key) => {
    if (Boolean(a[posKey + key])) {
      valA = valA + list[key] / numA;
      numA = numA * 10;
    }
    if (Boolean(b[posKey + key])) {
      valB = valB + list[key] / numB;
      numB = numB * 10;
    }
  });
  // if (valA === 0 && sortOrder === 'ascend') {
  //   valA = 999999;
  // }
  // if (valB === 0 && sortOrder === 'ascend') {
  //   valB = 999999;
  // }
  return valA - valB;
};

export const sortByPositionInitial = (a: object | any, b: object | any) => {
  // PG<SG<SF<PF<C
  const list: any = {
    PG: 1,
    G: 2,
    SG: 3,
    SF: 4,
    F: 5,
    PF: 6,
    C: 7,
    UTL: 8,
  };
  let valA = 0;
  let valB = 0;
  let numA = 1;
  let numB = 1;

  a?.split('/').forEach((item: any) => {
    valA = valA + list[item] / numA;
    numA = numA * 10;
  });

  b?.split('/').forEach((item: any) => {
    valB = valB + list[item] / numB;
    numB = numB * 10;
  });

  return valA - valB;
};

export const formatRankData = (data: any[]) => {
  const dataClone = [...data];
  dataClone.sort((a: any, b: any) => {
    return b.rankPeriod - a.rankPeriod;
  });

  return dataClone.map((item: any, index: number) => ({ ...item, rank: index + 1 }));
};

export const getDistrict = (id: number) => {
  if (id === DISTRICT.EAST) {
    return 'EAST';
  }
  if (id === DISTRICT.WEST) {
    return 'WEST';
  }
  if (id === DISTRICT.CENTER) {
    return 'CENTER';
  }
};

export const sortByTeam = (
  aId: number | string,
  bId: number | string,
  list: Array<any>,
  sortOrder?: 'ascend' | 'descend'
) => {
  // let valA = 0;
  // let valB = 0;
  // list?.every((item, index) => {
  //   if (valA !== 0 && valB !== 0) return false;
  //   if (item.id === aId) valA = index;
  //   if (item.id === bId) valB = index;
  //   return true;
  // });
  // console.log(aId, bId);
  const listId = list.map((item: any) => item.id);
  let valA = listId.indexOf(aId);
  let valB = listId.indexOf(bId);

  return valA - valB;
};

export const sortByTeamPlayerRanking = (a: any, b: any, list: Array<any>, sortOrder?: 'ascend' | 'descend') => {
  const listId = list.map((item: any) => item.id);
  let valA = listId.indexOf(a?.team?.id);
  let valB = listId.indexOf(b?.team?.id);
  if (!a?.team?.id && sortOrder === 'ascend') {
    valA = 9999;
  }
  if (!b?.team?.id && sortOrder === 'ascend') {
    valB = 9999;
  }
  if (!a?.team?.id && sortOrder === 'descend') {
    valA = -9999;
  }
  if (!b?.team?.id && sortOrder === 'descend') {
    valB = -9999;
  }

  return valA - valB;
};

export const sortByTeamPlayerRankingSS = (a: any, b: any, list: Array<any>, sortOrder?: 'ascend' | 'descend') => {
  const listId = list.map((item: any) => item.id);
  let valA = listId.indexOf(a?.teamId);
  let valB = listId.indexOf(b?.teamId);
  if (!a?.teamId && sortOrder === 'ascend') {
    valA = 9999;
  }
  if (!b?.teamId && sortOrder === 'ascend') {
    valB = 9999;
  }
  if (!a?.teamId && sortOrder === 'descend') {
    valA = -9999;
  }
  if (!b?.teamId && sortOrder === 'descend') {
    valB = -9999;
  }
  return valA - valB;
};

export const sortByTeamContest = (a: any, b: any, list: Array<any>, sortOrder?: 'ascend' | 'descend') => {
  const listId = list.map((item: any) => item.id);
  let valA = listId.indexOf(a?.teamId);
  let valB = listId.indexOf(b?.teamId);
  if (!a?.teamId && sortOrder === 'ascend') {
    valA = 9999;
  }
  if (!b?.teamId && sortOrder === 'ascend') {
    valB = 9999;
  }
  if (!a?.teamId && sortOrder === 'descend') {
    valA = -9999;
  }
  if (!b?.teamId && sortOrder === 'descend') {
    valB = -9999;
  }
  return sortOrder === 'ascend' ? valA - valB : valB - valA;
};

export const sortByPositionPlayerRanking = (a: any, b: any, sortOrder?: 'ascend' | 'descend', posKey = 'pos') => {
  const list: any = {
    PG: 1,
    SG: 2,
    SF: 3,
    PF: 4,
    C: 5,
  };
  let valA = 0;
  let valB = 0;
  let numA = 1;
  let numB = 1;
  Object.keys(list)?.forEach((key) => {
    if (Boolean(a?.position[posKey + key])) {
      valA = valA + list[key] / numA;
      numA = numA * 10;
    }
    if (Boolean(b?.position[posKey + key])) {
      valB = valB + list[key] / numB;
      numB = numB * 10;
    }
  });
  if (!position(a.position) && sortOrder === 'ascend') {
    valA = 9999999;
  }
  if (!position(b.position) && sortOrder === 'ascend') {
    valB = 9999999;
  }
  if (!position(a.position) && sortOrder === 'descend') {
    valA = -9999999;
  }
  if (!position(b.position) && sortOrder === 'descend') {
    valB = -9999999;
  }

  return valA - valB;
};
export const sortByPositionPlayerRankingSS = (a: any, b: any, sortOrder?: 'ascend' | 'descend', posKey = 'pos') => {
  const list: any = {
    PG: 1,
    SG: 2,
    SF: 3,
    PF: 4,
    C: 5,
  };
  let valA = 0;
  let valB = 0;
  let numA = 1;
  let numB = 1;
  a?.position?.split('/').forEach((item: any) => {
    valA = valA + list[item] / numA;
    numA = numA * 10;
  });

  b?.position?.split('/').forEach((item: any) => {
    valB = valB + list[item] / numB;
    numB = numB * 10;
  });
  if (!a.position && sortOrder === 'ascend') {
    valA = 9999999;
  }
  if (!b.position && sortOrder === 'ascend') {
    valB = 9999999;
  }
  if (!a.position && sortOrder === 'descend') {
    valA = -9999999;
  }
  if (!b.position && sortOrder === 'descend') {
    valB = -9999999;
  }

  return valA - valB;
};

export const sortByPositionContest = (a: any, b: any, sortOrder?: 'ascend' | 'descend', posKey = 'pos') => {
  const list: any = {
    PG: 1,
    G: 2,
    SG: 3,
    SF: 4,
    F: 5,
    PF: 6,
    C: 7,
    UTL: 8,
  };
  let valA = 0;
  let valB = 0;
  let numA = 1;
  let numB = 1;
  a?.position?.split('/').forEach((item: any) => {
    valA = valA + list[item] / numA;
    numA = numA * 10;
  });

  b?.position?.split('/').forEach((item: any) => {
    valB = valB + list[item] / numB;
    numB = numB * 10;
  });
  if (!a.position && sortOrder === 'ascend') {
    valA = 9999999;
  }
  if (!b.position && sortOrder === 'ascend') {
    valB = 9999999;
  }
  if (!a.position && sortOrder === 'descend') {
    valA = -9999999;
  }
  if (!b.position && sortOrder === 'descend') {
    valB = -9999999;
  }

  return sortOrder === 'ascend' ? valA - valB : valB - valA;
};

export const sortByNationalityType = (
  a: { nationalityType: NationalityType },
  b: { nationalityType: NationalityType },
  sortOrder?: 'ascend' | 'descend'
) => {
  //Sort priority 0 > 3 > 1 > 2
  const typeValue = {
    0: 1,
    3: 10,
    1: 100,
    2: 1000,
  };

  return sortOrder === 'ascend'
    ? typeValue[a.nationalityType] - typeValue[b.nationalityType]
    : typeValue[b.nationalityType] - typeValue[a.nationalityType];
};

export const sortByNationalityTypeNoSortOrder = (
  a: { nationalityType: NationalityType },
  b: { nationalityType: NationalityType },
  sortOrder?: 'ascend' | 'descend'
) => {
  //Sort priority 0 > 3 > 1 > 2
  const typeValue = {
    0: 1,
    3: 10,
    1: 100,
    2: 1000,
  };

  return typeValue[a.nationalityType] - typeValue[b.nationalityType];
};
export const fixDecimal = (data: any, numberDecimal: number = 1) => {
  const readData = Number(data) || 0;
  return readData.toFixed(numberDecimal);
};

export const convertDayToSend = (day: moment.Moment) => {
  const startTimeDay = moment(day).startOf('day');
  const endTimeDay = moment(day).endOf('day');
  return { startAt: startTimeDay.toISOString(), endAt: endTimeDay.toISOString() };
};

export const fixDecimalWithType = (
  data: any,
  stat:
    | 'value'
    | 'G'
    | 'GS'
    | 'MPG'
    | 'FGM'
    | 'FGA'
    | 'FTM'
    | 'FTA'
    | 'TRIFGM'
    | 'TRIFGA'
    | 'OR'
    | 'DR'
    | 'TR'
    | 'AST'
    | 'STL'
    | 'BLK'
    | 'TO'
    | 'TD'
    | 'DD'
    | 'F'
    | 'PTS'
    | 'ATO'
    | 'FGRate'
    | 'FTRate'
    | 'TRIFGRate'
    | 'percent'
    | 'ownedRatio',
  type: number = 0
) => {
  const readData = Number(data) || 0;
  let noDecimal: any = [];
  let oneDecimal: any = [];
  let twoDecimal: any = [];
  let percentFormat: any = [];
  let numberToPercentFormat: any = ['percent'];

  if (AVG_PERIOD.includes(type)) {
    noDecimal = ['G', 'GS'];
    oneDecimal = [
      'value',
      'MPG',
      'FGM',
      'FGA',
      'FTM',
      'FTA',
      'TRIFGM',
      'TRIFGA',
      'OR',
      'DR',
      'TR',
      'AST',
      'STL',
      'BLK',
      'TO',
      'TD',
      'DD',
      'F',
    ];
    twoDecimal = ['ATO'];
    percentFormat = ['FGRate', 'FTRate', 'TRIFGRate', 'ownedRatio'];
    if (
      [Period.AVG_GUESS_THIS_SS, Period.AVG_LAST_1_WEEK, Period.AVG_LAST_2_WEEK, Period.AVG_LAST_1_MONTH].includes(type)
    ) {
      noDecimal.push('PTS');
    } else {
      oneDecimal.push('PTS');
    }
  } else {
    noDecimal = [
      'G',
      'GS',
      'FGM',
      'FGA',
      'FTM',
      'FTA',
      'TRIFGM',
      'TRIFGA',
      'OR',
      'DR',
      'TR',
      'AST',
      'STL',
      'BLK',
      'TO',
      'TD',
      'DD',
      'F',
      'PTS',
    ];
    oneDecimal = ['value', 'MPG'];
    twoDecimal = ['ATO'];
    percentFormat = ['FGRate', 'FTRate', 'TRIFGRate', 'ownedRatio'];
  }
  if (noDecimal.includes(stat)) {
    return readData.toFixed(0);
  }
  if (oneDecimal.includes(stat)) {
    return readData.toFixed(1);
  }
  if (twoDecimal.includes(stat)) {
    return readData.toFixed(2);
  }
  if (percentFormat.includes(stat)) {
    return `${readData.toFixed(1)}%`;
  }
  if (numberToPercentFormat.includes(stat)) {
    return `${(Number(readData) * 100).toFixed(1)}%`;
  }
  return readData.toFixed(1);
};

export const formatPercentToDecimal = (value: any) => Number.parseFloat(value) / 100;

export const formatDataForCSV = (data: any[]) => data.map((item: any) => (item ? `=""${item}""` : ''));

export const getMemberInformation = (data: any = {}) => {
  const { memberType, expiredDateSubscription, isSubscription } = data;
  const isNewMember = memberType === MemberType.TRAIL && !expiredDateSubscription && isSubscription === 0;
  const isOutDateTrial30Days = MemberType.TRAIL && expiredDateSubscription && isSubscription === 0;
  const isNormalSubscription = MemberType.IS_FREE && expiredDateSubscription && isSubscription === 1;
  return { isNewMember, isSubscription, isOutDateTrial30Days, isNormalSubscription };
};

interface IFantasyYear {
  id: number;
  fantasyYear: number;
  startAt: string;
  regularSeasonFrom: string;
  regularSeasonTo: string;
}

export const getCurrentFantasyYear = (listFY: IFantasyYear[]) =>
  listFY.find((year: IFantasyYear) => moment().isBetween(year.regularSeasonFrom, year.regularSeasonTo))?.fantasyYear;

export const getTextNationality = (type: NationalityType) => {
  switch (type) {
    case NationalityType.JAPANESE_NATIONALITY:
      return '';
    case NationalityType.NATURALIZATION:
    case NationalityType.ASIAN_SPECIAL_FRAME:
      return text.symbolSpecialPlayer;
    case NationalityType.FOREIGN_PLAYER:
      return text.symbolNationalityFrame;
    default:
      return '';
  }
};

export const getTextNationalityDetailPlayer = (type: NationalityType) => {
  switch (type) {
    case NationalityType.JAPANESE_NATIONALITY:
      return text.japanese;
    case NationalityType.NATURALIZATION:
      return text.naturalization;
    case NationalityType.ASIAN_SPECIAL_FRAME:
      return text.asianSpecialFrame;
    case NationalityType.FOREIGN_PLAYER:
      return text.foreignNationality;
    default:
      return '';
  }
};
