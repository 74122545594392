import { sendPost, sendPut, sendGet, sendDelete } from './axios';

// eslint-disable-next-line import/prefer-default-export
export const login = (payload: any) => sendPost('/auth/login', payload);
export const loginGoogle = (payload: any) => sendPost('/auth/login-google', payload);
export const loginTwitter = (payload: any) => sendPost('/auth/login-twitter', payload);

export const register = (payload: any) => sendPost('/auth/register', payload);
export const registerGoogle = (payload: any) => sendPost('/auth/register-google', payload);
export const getOauthTokenTwitter = () => sendPost('/auth/twitter-request-token');
export const getAccessTokenTwitter = (payload: any) => sendPost('/auth/twitter-access-token', payload);
export const registerTwitter = (payload: any) => sendPost('/auth/register-twitter', payload);

export const getProfile = () => sendGet('/auth/profile');
export const changeEmail = (payload: any) => sendPut('/auth/change-email', payload);
export const changePassword = (payload: any) => sendPut('/auth/change-password', payload);
export const changeCreditCard = (payload: any) => sendPost('/payment-gmo/save-or-update-card-GMO', payload);
export const subscriptionGMO = () => sendPost('/payment-gmo');
export const cancelSubscriptionGMO = () => sendPost('/payment-gmo/cancel-subscription');
export const verifyEmail = (payload: any) => sendPut('/auth/verify-email', payload);
export const resendVerify = (payload: any) => sendPut('/auth/resend-email', payload);
export const resetPassword = (payload: any) => sendPut('/auth/reset-password', payload);
export const createVerifyCode = (payload: any) => sendPost('/auth/create-verify-code', payload);

export const linkNewLeague = (payload: any) => sendPost('/league', payload);
export const changeMyTeam = (payload: any) => sendPost('/league/add-team', payload);
export const removeLeague = (payload: any) => sendDelete(`/league/${payload.id}`);

export const signUp = (payload: any) => sendPost('/auth/signup', payload);

export const changeLeague = (payload: { memberLeagueId: number }) =>
  sendPut('/league/change-default-member-league', payload);

export const getResources = () => sendGet('/common/resources');

export const subscriptionTrial = () => sendPost('/payment-gmo/subscription');

export const calculateAccess = (payload: { route: string }) => sendPost('/common/calculate-access-route', payload);
