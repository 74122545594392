import React from 'react';
import './App.scss';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import RootWrapper from './wrappers/RootWrapper/index';
import { QueryClientProvider, QueryClient, QueryCache } from 'react-query';
import { handleErrorMessage } from 'helper';
import dateUtils from 'utils/DateUtils';
import configs from 'config';
import { MaintainType } from 'common';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => {
      if (error?.response?.data?.errorKey === 'MAINTAINING') {
        const metadata = error?.response?.data?.metadata;
        const startAt = dateUtils.getFullTime(metadata?.startAt);
        const endAt = dateUtils.getFullTime(metadata?.endAt);
        localStorage.setItem('type', metadata?.type);
        if (metadata.type === MaintainType.TEXT) {
          let content = metadata?.content;
          content = content.replace('{startAt}', startAt);
          content = content.replace('{endAt}', endAt);
          localStorage.setItem('content', content);
        } else {
          localStorage.setItem('image', metadata?.image);
        }
        history.push({
          pathname: '/maintain',
        });
      } else if (error?.response?.data?.errorKey === 'You_Do_Not_Subscribe_To_Any_Package') {
        history.push({
          pathname: '/subscription',
        });
        queryClient.invalidateQueries('profile');
      } else {
        handleErrorMessage(error);
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 24 * 3600 * 1000, // cache for 1 day
    },
  },
});

declare global {
  namespace JSX {
    interface IntrinsicElements {
      marquee: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> | any;
    }
  }
}

export const history = createBrowserHistory();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <HistoryRouter history={history}>
        <RootWrapper />
      </HistoryRouter>
    </QueryClientProvider>
  );
}

export default App;
