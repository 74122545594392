import { Menu, Dropdown, Divider, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { DownOutlined, CaretRightOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import ModalCustom from 'components/Modal';
import styles from './styles.module.scss';
import { useState } from 'react';
import { ReactComponent as Money } from 'assets/images/money.svg';
import { ReactComponent as Flag } from 'assets/images/flag.svg';
import { text } from 'helper/text';
import { useQueryClient } from 'react-query';
import { MemberType, WebRouter } from 'common';
import configs from 'config';
import { getMemberInformation } from 'helper';
import classNames from 'classnames';

const listTitleDFSTool = [
  { title: '選手ランキング', link: WebRouter.PLAYER_RANKING },
  { title: 'オプティマイザー', link: WebRouter.OPTIMIZER },
  { title: 'Daily Summary', link: WebRouter.DAILY_SUMMARY },
  { title: 'Box Scores', link: WebRouter.BOX_SCORE },
  { title: '過去大会の成績', link: WebRouter.PAST_CONTEST },
];

const listTitleSeasonTool = [
  { title: '選手ランキング', link: WebRouter.PLAYER_RANKING_SS },
  { title: 'チームランキング', link: WebRouter.TEAM_RANKING_SS },
  { title: 'H2Hスケジュール', link: WebRouter.H2H_DAILY },
  { title: 'Depth Charts', link: WebRouter.DEPTH_CHART },
  { title: 'シミュレーター', link: WebRouter.SIMULATOR },
  { title: 'Daily Summary', link: WebRouter.DAILY_SUMMARY_SS },
  { title: 'Box Scores', link: WebRouter.BOX_SCORE_SS },
];

export default function Toolbar() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const pathname = window.location.pathname;
  const isAuthenticated = Boolean(Cookies.get('token') || '');
  const profile: any = queryClient.getQueryData('profile');

  const isMaintain = window.location.pathname === WebRouter.MAINTAIN;

  const { isNewMember, isSubscription, isOutDateTrial30Days } = getMemberInformation(profile?.data);

  const DFSToolMenu = (
    <Menu>
      <Menu.Divider className={styles.divider} />
      {listTitleDFSTool.map((item: any) => (
        <Menu.Item
          className={pathname === item.link ? 'bg-blue color-white ' : ''}
          onClick={() => check(item)}
          key={item.link}
        >
          <CaretRightOutlined />
          <b>{item.title}</b>
        </Menu.Item>
      ))}
    </Menu>
  );

  const SeasonToolMenu = (
    <Menu>
      <Menu.Divider className={styles.divider} />
      {listTitleSeasonTool.map((item: any) => (
        <Menu.Item
          className={pathname === item.link ? 'bg-blue color-white' : ''}
          onClick={() => check(item)}
          key={item.link}
        >
          <CaretRightOutlined />
          <b>{item.title}</b>
        </Menu.Item>
      ))}
    </Menu>
  );

  const check = (route: any) => {
    const listRoutersPaid = [WebRouter.OPTIMIZER, WebRouter.TEAM_RANKING_SS, WebRouter.H2H_DAILY, WebRouter.SIMULATOR];
    const listRoutesFree = [
      WebRouter.PLAYER_RANKING,
      WebRouter.DAILY_SUMMARY,
      WebRouter.BOX_SCORE,
      WebRouter.PAST_CONTEST,
      WebRouter.PLAYER_RANKING_SS,
      WebRouter.DEPTH_CHART,
      WebRouter.DAILY_SUMMARY_SS,
      WebRouter.BOX_SCORE_SS,
    ];
    if (isAuthenticated || listRoutesFree.includes(route.link)) {
      navigate(route.link);
    } else {
      // if (isOutDateTrial30Days) {
      //   navigate('/subscription');
      // } else {
      //   setOpen(true);
      // }
      setOpen(true);
    }
  };

  const getContentOfModal = (isAuthen: boolean, isNew: boolean) => {
    if (isAuthen) {
      //Free one month

      // return isNew ? (
      //   <>
      //     <b className="fontsize-24 ">{text.membershipAgreementDialog2}</b>
      //     <b className="fontsize-24 color-red4 mt-34">{text.membershipFirstTimeDialog}</b>
      //     {pathname !== '/setting-profile' && (
      //       <Link to="/setting-profile">
      //         <Button
      //           size="large"
      //           className=" mt-34 button-yellow width-fit pl-25 pr-25"
      //           onClick={() => setOpen(false)}
      //         >
      //           {text.toProfile}
      //         </Button>
      //       </Link>
      //     )}
      //   </>
      // ) : (
      //   <>
      //     <b className="fontsize-24 ">{text.membershipAgreementDialog}</b>
      //     {pathname !== '/setting-profile' && (
      //       <Link to="/setting-profile">
      //         <Button
      //           size="large"
      //           className=" mt-34 button-yellow width-fit pl-25 pr-25"
      //           onClick={() => setOpen(false)}
      //         >
      //           {text.toProfile}
      //         </Button>
      //       </Link>
      //     )}
      //   </>
      // );
      return (
        <>
          <b className="fontsize-24 ">{text.membershipAgreementDialog}</b>
          {pathname !== '/setting-profile' && (
            <Link to="/setting-profile">
              <Button
                size="large"
                className=" mt-34 button-yellow width-fit pl-25 pr-25"
                onClick={() => setOpen(false)}
              >
                {text.toProfile}
              </Button>
            </Link>
          )}
        </>
      );
    } else {
      return (
        <>
          <b className="fontsize-24 ">{text.notLogin}</b>
          {pathname !== '/login' && (
            <Link to="/login">
              <Button type="primary" size="large" className="button-fill mt-15" onClick={() => setOpen(false)}>
                {text.toLogin}
              </Button>
            </Link>
          )}
        </>
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <Dropdown
          overlay={DFSToolMenu}
          trigger={['click']}
          align={{ offset: [0, 0] }}
          openClassName={styles.toolbar__button_active}
          overlayClassName={styles.dropdown}
          className={styles.toolbar__menu_item}
          disabled={isMaintain}
        >
          <div className={classNames([styles.toolbar__button], { [styles.toolbar__maintain]: isMaintain })}>
            <Money className={styles.toolbar__icon} />
            {text.dfsTool}
            <DownOutlined className="ml-8" />
          </div>
        </Dropdown>
        <Divider type="vertical" className={styles.divider_vertical} />
        <Dropdown
          overlay={SeasonToolMenu}
          trigger={['click']}
          align={{ offset: [0, 0] }}
          openClassName={styles.toolbar__button_active}
          overlayClassName={styles.dropdown}
          className={styles.toolbar__menu_item}
          disabled={isMaintain}
        >
          <div className={classNames([styles.toolbar__button], { [styles.toolbar__maintain]: isMaintain })}>
            <Flag className={styles.toolbar__icon} />
            {text.ssTool} <DownOutlined className="ml-8" />
          </div>
        </Dropdown>
      </div>
      <ModalCustom open={open} close={() => setOpen(false)}>
        <div className="d-flex al-center fd-column">{getContentOfModal(isAuthenticated, isNewMember)}</div>
      </ModalCustom>
    </div>
  );
}
