import moment from 'moment';

const dateUtils = {
  getYearMonthDayWithUnderscore(dateString: string = '') {
    if (!dateString) return '';
    return moment(dateString).locale('ja').format(`YYYY_MM_DD`);
  },
  getYearMonthDayWithSlash(dateString: string = '') {
    if (!dateString) return '';
    return moment(dateString).locale('ja').format(`YYYY-MM-DD (dd)`);
  },
  getMonthDayWithSlash(dateString: string = '') {
    if (!dateString) return '';
    return moment(dateString).locale('ja').format(`MM-DD(dd)`);
  },
  getNextDay(dateString: string = '') {
    if (!dateString) return '';
    return moment(dateString).add(1, 'day').locale('ja');
  },
  getYesterday(dateString: string = '') {
    if (!dateString) return '';
    return moment(dateString).subtract(1, 'day').locale('ja');
  },
  getYearMonthDayWithTimeZone(dateString: string = '') {
    if (!dateString) return '';
    return moment(dateString, 'YYYY-MM-DD HH:mm:ss ZZ').format('YYYY-MM-DD');
  },
  getYearMonthDayJPWithTimeZone(dateString: string = '') {
    if (!dateString) return '';
    return moment(dateString, 'YYYY-MM-DD HH:mm:ss ZZ').format('YYYY年MM月DD日');
  },
  getFullTime(dateString: string = '') {
    if (!dateString) return '';
    return moment(dateString).format('YYYY-MM-DD HH:mm');
  },
};

export default dateUtils;
