import React, { lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import config from 'config';
import PrivateWrapper from './private';
import configs from 'config';
import { WebRouter } from 'common';

const SettingProfile = lazy(() => import('pages/SettingProfile'));
const Card = lazy(() => import('pages/UserCard'));
const Subscription = lazy(() => import('pages/Subscription'));

const Optimizer = lazy(() => import('pages/Optimizer'));
const Simulator = lazy(() => import('pages/Simulator'));

export default function AuthWrapper() {
  const token = Cookies.get('token') || '';
  const isAuthenticated = Boolean(token);
  const location = useLocation();

  useEffect(() => {
    (window as any).Multipayment.init(config.GMO_SHOP_ID);
  }, []);

  if (!isAuthenticated && location?.pathname !== '/box-scored-ss') return <Navigate to="/" />;

  return (
    <Routes>
      <Route path="/setting-profile" element={<SettingProfile />} />
      <Route path="/subscription" element={<Subscription />} />
      <Route path="/card" element={<Card />} />
      <Route path={WebRouter.OPTIMIZER} element={<Optimizer />} />
      <Route path={WebRouter.SIMULATOR} element={<Simulator />} />
      <Route path="/*" element={<PrivateWrapper />} />
    </Routes>
  );
}
