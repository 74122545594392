import { Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ReactComponent as SettingIcon } from 'assets/images/setting.svg';

import Logo from 'assets/images/logo.png';
import { text } from 'helper/text';
import { useQueryClient } from 'react-query';
import { handleErrorMessage } from 'helper';
import { changeLeague } from 'api/authentication';
import classNames from 'classnames';
import { WebRouter } from 'common';

export default function Header() {
  const token = Cookies.get('token') || '';
  const isAuthenticated = Boolean(token);
  const navigate = useNavigate();
  const isMaintain = window.location.pathname === WebRouter.MAINTAIN;
  const queryClient = useQueryClient();
  const profile: any = queryClient.getQueryData('profile');

  const [leagueSelected, setLeagueSelected] = useState<any>();

  useEffect(() => {
    if (profile) {
      setLeagueSelected(profile?.data?.memberLeague.find((item: any) => item.isDefault === 1)?.id);
    }
  }, [profile]);

  const logout = () => {
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    localStorage.removeItem('user_id');

    window.location.href = '/login';
  };

  const handleChangeLeague = async (data: number) => {
    try {
      await changeLeague({ memberLeagueId: data });
      window.location.reload();
    } catch (error) {
      handleErrorMessage(error);
    }
  };

  return (
    <div className={styles.header}>
      {isMaintain ? (
        <img src={Logo} className={styles.logo} />
      ) : (
        <Link to="/">
          <img src={Logo} className={styles.logo} />
        </Link>
      )}
      {isMaintain ? (
        <div className="d-flex">
          <Button className="fontsize-18 outline-curious-blue button-outline mr-15 maintain-btn" size="large" disabled>
            <Link to="/sign-up">
              <b>{text.register}</b>
            </Link>
          </Button>
          <Button type="primary" className="fontsize-18 button-fill mr-15 maintain-btn" size="large" disabled>
            <Link to="/login">
              <b>{text.login}</b>
            </Link>
          </Button>
        </div>
      ) : !isAuthenticated ? (
        <div className="d-flex">
          <Button className="fontsize-18 outline-curious-blue button-outline mr-15" size="large">
            <Link to="/sign-up">
              <b>{text.register}</b>
            </Link>
          </Button>
          <Button type="primary" className="fontsize-18 button-fill mr-15" size="large">
            <Link to="/login">
              <b>{text.login}</b>
            </Link>
          </Button>
        </div>
      ) : (
        <div className={styles.search_container}>
          <Select
            placeholder={text.leagueName}
            className={styles.search}
            onChange={(data: number) => setLeagueSelected(data)}
            value={leagueSelected}
          >
            {profile?.data?.memberLeague.map((item: any) => (
              <Select.Option value={item.id} key={item.id}>
                {item.leagueName}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            className="fontsize-18 button-fill width-fit plr-30 mr-5 ml-5"
            size="large"
            onClick={() => handleChangeLeague(leagueSelected)}
          >
            {text.refresh}
          </Button>
          <Button
            type="primary"
            className={classNames('button-outline', [styles.btn_logout])}
            size="large"
            onClick={logout}
          >
            {text.logout}
          </Button>
          <Link to="/setting-profile" className={styles.setting__icon}>
            <SettingIcon />
          </Link>
        </div>
      )}
    </div>
  );
}
