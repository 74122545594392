import React, { ReactElement } from 'react';
import styles from './style.module.scss';
import { Modal, Button, Row, Col } from 'antd';
import { text } from 'helper/text';
import warningIcon from 'assets/images/warning.svg';

interface IProps {
  open: boolean;
  close?: Function | any;
  className?: string;
  children: ReactElement;
  closable?: boolean;
}

export default function ModalConfirm({ open = false, close = () => {}, children, closable = true }: IProps) {
  return (
    <Modal
      visible={open}
      title=""
      centered
      onCancel={close}
      footer={null}
      closable={closable}
      width={650}
      bodyStyle={{ padding: 0 }}
      className={styles.modal}
    >
      <div className={styles.modalBody}>
        <div className="fontsize-24 mb-20 pre-wrap text-center">
          <img src={warningIcon} height={96} className="mb-24" />
          {children}
        </div>
      </div>
    </Modal>
  );
}
