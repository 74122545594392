import { Button, Divider, Select } from 'antd';
import React from 'react';
import styles from './styles.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from 'services/store';
import { WebRouter } from 'common';

export default function Footer() {
  const navigate = useNavigate();
  const isMaintain = window.location.pathname === WebRouter.MAINTAIN;

  return (
    <div className={styles.footer}>
      <div className={styles.footer__content}>
        {!isMaintain && (
          <div className="d-flex fontsize-16">
            <Link to="/term" className={styles.link_container__link}>
              利用規約
            </Link>
            <Divider type="vertical" className={styles.divider} />
            <Link to="//mynet.co.jp/" className={styles.link_container__link}>
              運営会社
            </Link>
            <Divider type="vertical" className={styles.divider} />

            <Link to="//mynet.co.jp/privacypolicy/" className={styles.link_container__link}>
              プライバシーポリシー
            </Link>
            <Divider type="vertical" className={styles.divider} />

            <Link to="/inquiry" className={styles.link_container__link}>
              お問い合わせ
            </Link>
            <Divider type="vertical" className={styles.divider} />

            <Link to="/term" className={styles.link_container__link}>
              特定商取引法及び資金決済法に基づく表記
            </Link>
          </div>
        )}
        <div className="mt-24 mb-16">公益社団法人ジャパン・プロフェッショナル・バスケットボールリーグ</div>
        <div>©JAPAN PROFESSIONAL BASKETBALL LEAGUE All RIGHTS RESERVED.</div>
        <div className="mb-24">
          スクレイピング、クローリングその他類似の手段を用いて本サイトの情報を取得し活用することを禁じます。
        </div>
        <div>© GAMEDAY Interactive Inc.</div>
      </div>
    </div>
  );
}
