import { text } from 'helper/text';

export enum loginEnum {
  NORMAL = 1,
  GOOGLE = 2,
  TWITTER = 3,
}

export enum TwitterType {
  LOGIN = '1',
  SIGNUP = '2',
}

export enum VerifyType {
  SIGNUP = 1,
  RESET_PASSWORD = 2,
  CHANGE_EMAIL = 3,
}

export enum LEAGUE_TYPE {
  B1 = 1,
  B2 = 2,
}

export enum Season {
  B2122 = 'B2122',
}

export enum Period {
  THIS_DAY = 1,
  THIS_SS = 9,
  GUES_THIS_SS = 13,
  LAST_1_WEEK = 3,
  LAST_2_WEEK = 5,
  LAST_1_MONTH = 7,
  PREV_SS = 11,
  AVG_THIS_SS = 8,
  AVG_LAST_1_WEEK = 2,
  AVG_LAST_2_WEEK = 4,
  AVG_LAST_1_MONTH = 6,
  AVG_PREV_SS = 10,
  AVG_GUESS_THIS_SS = 12,
}

export enum PlayerType {
  ALL = 1,
  COLLECT_OWN = 2,
  COLLECT = 3,
}

export enum Team {
  ALL = 0,
}

export const ALL_TEAM = [
  {
    id: Team.ALL,
    name: text.allTeam,
  },
];

export enum Position {
  PG = 1,
  SG = 2,
  G = 3,
  SF = 4,
  PF = 5,
  F = 6,
  C = 7,
  UTL = 8,
  BN = 9,
}

export enum Stats {
  FGM = 1,
  FGRate = 2,
  FTM = 3,
  FTRate = 4,
  TriFGM = 5,
  TriFGRate = 6,
  OR = 7,
  TR = 8,
  AST = 9,
  STL = 10,
  BLK = 11,
  ATO = 12,
  TD = 13,
  PTS = 14,
}

export enum ownerTeamPlayer {
  IS_ANOTHER_TEAM = 2,
  IS_MY_TEAM = 1,
  IS_NOT_ANY_TEAM = 0,
}

export enum OptionPlayer {
  ALL = 1,
  OWNER = 2,
  FREE = 3,
}

export const SEASON = [
  {
    id: Season.B2122,
    name: 'B.LEAGUE 2021-22',
  },
];

export const AVG_PERIOD = [
  Period.AVG_THIS_SS,
  Period.AVG_GUESS_THIS_SS,
  Period.AVG_LAST_1_WEEK,
  Period.AVG_LAST_2_WEEK,
  Period.AVG_LAST_1_MONTH,
  Period.AVG_PREV_SS,
];

export const PERIOD = [
  {
    id: Period.THIS_SS,
    name: text.thisSS,
  },
  {
    id: Period.GUES_THIS_SS,
    name: text.guesThisSS,
  },
  {
    id: Period.LAST_1_WEEK,
    name: text.last1Week,
  },

  {
    id: Period.LAST_2_WEEK,
    name: text.last2Week,
  },
  {
    id: Period.LAST_1_MONTH,
    name: text.last1Month,
  },
  {
    id: Period.PREV_SS,
    name: text.prevSS,
  },
  {
    id: Period.AVG_THIS_SS,
    name: text.avgThisSS,
  },
  {
    id: Period.AVG_GUESS_THIS_SS,
    name: text.avgGuessSS,
  },
  {
    id: Period.AVG_LAST_1_WEEK,
    name: text.avgLast1Week,
  },

  {
    id: Period.AVG_LAST_2_WEEK,
    name: text.avglast2Week,
  },
  {
    id: Period.AVG_LAST_1_MONTH,
    name: text.avgLast1Month,
  },
  {
    id: Period.AVG_PREV_SS,
    name: text.avgPrevSS,
  },
];

export const AVG_PERIOD_SELECT = [
  { id: Period.AVG_THIS_SS, name: text.avgThisSS },
  { id: Period.AVG_GUESS_THIS_SS, name: text.avgGuessSS },
  { id: Period.AVG_LAST_1_WEEK, name: text.avgLast1Week },
  { id: Period.AVG_LAST_2_WEEK, name: text.avglast2Week },
  { id: Period.AVG_LAST_1_MONTH, name: text.avgLast1Month },
  { id: Period.AVG_PREV_SS, name: text.avgPrevSS },
];

export const SS_PERIOD = {
  PREV_SS: {
    start: '2020/10/02',
    end: '2021/05/02',
  },
  THIS_SS: {
    start: '2021/09/30',
    end: '2022/05/08',
  },
};

export const PLAYER_TYPE = [
  {
    id: PlayerType.ALL,
    name: text.allPlayer,
  },
  {
    id: PlayerType.COLLECT,
    name: text.collectPlayer,
  },
  {
    id: PlayerType.COLLECT_OWN,
    name: text.collectOwnPlayer,
  },
];

export enum DISTRICT {
  EAST = 1,
  WEST = 2,
  CENTER = 3,
}

export enum GameStatus {
  BEFORE_THE_MATCH = 1,
  DURING_THE_MATCH = 2,
  AFTER_THE_MATCH = 3,
}

export enum MemberType {
  IS_FREE = 1,
  IS_PRIVATE = 2,
  TRAIL = 3,
}

export enum WebRouter {
  PLAYER_RANKING = '/player-rankings',
  OPTIMIZER = '/optimizer',
  DAILY_SUMMARY = '/daily-summary',
  BOX_SCORE = '/box-scored',
  PAST_CONTEST = '/past-contest',
  PLAYER_RANKING_SS = '/player-rankings-ss',
  TEAM_RANKING_SS = '/team-rankings-ss',
  H2H_DAILY = '/schedule-h2h-ss',
  DEPTH_CHART = '/depth-chart-ss',
  SIMULATOR = '/simulator-ss',
  DAILY_SUMMARY_SS = '/daily-summary-ss',
  BOX_SCORE_SS = '/box-scored-ss',
  MAINTAIN = '/maintenance',
}

export enum PaymentStatus {
  SUCCESS = 1,
  FAILED = 0,
}

export enum MaintainType {
  IMAGE = 1,
  TEXT = 0,
}

export enum NationalityType {
  JAPANESE_NATIONALITY = 0,
  NATURALIZATION = 1,
  ASIAN_SPECIAL_FRAME = 2,
  FOREIGN_PLAYER = 3,
}
